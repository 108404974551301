<!--
 * @Date: 2021-09-28 14:05:16
 * @Author: Jeason
 * @LastEditors: Jeason
 * @LastEditTime: 2021-09-28 14:05:20
 * @Description: 角色权限
 * @FilePath: \sass_web\maintenance_expert\src\views\SystemSettings\role\index.vue
-->
<template>
  <div class='JZContainer'>
     <QueryForm label-position="right" ref="JZForm" :model="JZForm" label-width="112px" class="content_block" @search='handleSearch' @reset="handleReset">
      <el-form-item label="角色名称：" prop="name" >
        <el-input placeholder="请输入角色名称" v-model.trim="JZForm.name" clearable></el-input>
      </el-form-item>
    </QueryForm>
    <TableWithPagination :tableData="targetLists"
      class="content_block"
      :columnList="columnList"
      :leftToolbar="tableButtons"
      :pagination.sync="pagination"
      :sortInfo.sync="updateSortInfo"
      @current-change="getTargetLists"
      @sort-change="getTargetLists"
    ></TableWithPagination>
  <JZDrawer ref="JZDrawer"  @handleSearch='handleSearch'></JZDrawer>
  </div>
</template>

<script>
import InfoMixin from '@/mixins/infoMixin.js';
import SystemSetting from '@/api/systemSetting';
import JZDrawer from './components/JZDrawer';
export default {
  name: 'Role',
  components: {
    JZDrawer,
  },
  mixins: [
    InfoMixin,
  ],
  methods: {
    handleReset () {
      this.JZForm = this.$options.data().JZForm;
      this.handleSearch();
    },
    handleSearch () {
      this.pagination.currentPage = 1;
      this.getTargetLists();
    },
    getTargetLists () {
      let currentParams = this.getCurrentParams();
      SystemSetting.getRoleLists(currentParams).then(this.getTargetListsSuccessFun);
    },
    getCurrentParams () {
      return {
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        name: this.JZForm.name,
        orderBy: [{
          orderByProperty: 'lastUpdateTime',
          orderByType: 'DESC',
        }],
      };
    },
    handleDetail (info, index) {
      this.$refs.JZDrawer.isStatus = index;
      this.$refs.JZDrawer.JZForm.id = info.id;
      this.$refs.JZDrawer.init();
    },
    addNew () {
      this.$refs.JZDrawer.isStatus = 1;
      this.$refs.JZDrawer.JZForm.id = '';
      this.$refs.JZDrawer.init();
    },
    // 删除角色
    handleDelete (info) {
      SystemSetting.checkRelEmployeeFlag({roleId: info.id}).then((jsonData) => {
        if (jsonData.heads.code === 200) {
          let isCheckRelEmployeeFlag = jsonData.body;
          let currentMessage = isCheckRelEmployeeFlag ? '删除角色后将导致已配置该角色的员工失去该角色下的权限，是否要继续删除？' : '';
          let currentTitle = isCheckRelEmployeeFlag ? '该角色已配置员工，确定删除该角色吗?' : '确定删除该角色吗?';
          this.$confirmAlert(currentMessage, currentTitle).then(() => {
            SystemSetting.deleteRole({id: info.id}).then(this.handleReset);
          });
        } else {
          this.$JZShowAlert(jsonData.heads.message, 'error');
        }
      });
    },
  },
  data () {
    return {
      isShow: false,
      JZForm: {
        name: '',
      },
      currentTitle: '新增员工',
      targetLists: [],
      tableButtons: [
        {
          name: '新增', // 按钮内容(必填)
          status: 'primary', // 按钮样式
          size: 'small', // 大小尺寸
          code: 'addNew', // code(必填)
          icon: 'vxe-icon--plus', // 按钮内部icon
          permission: 'system:role:add', // 权限码，若为空字符串或不设置则表示不进行权限控制
          click: this.addNew, // 点击回调事件
        },
      ],
      columnList: [
        {
          tooltip: true,
          label: '序号',
          minWidth: '110px',
          prop: '',
          type: 'index',
        },
        {
          tooltip: true,
          label: '角色名称',
          minWidth: '110px',
          prop: 'name',
          render: (h, {row}) => {
            let actions = [];
            let queryStatus = (this.$store.state.Auth && this.$store.state.Auth.actionPermissionMap['system:role:view']) || false;
            if (queryStatus) {
              actions.push(h('span', {
                domProps: {
                  className: 'blue',
                },
                on: {
                  click: this.handleDetail.bind(this, row, 3),
                },
              }, row.name));
              return h('div', actions);
            } else {
              return h('div', row.name);
            }
          },
        },
        {
          tooltip: true,
          label: '角色描述',
          minWidth: '280px',
          prop: 'description',
        },
        {
          tooltip: true,
          label: '操作',
          minWidth: '110px',
          prop: 'id',
          render: (h, {row}) => {
            let actions = [];
            actions.push(h('span', {
              domProps: {
                className: 'table-action',
              },
              directives: [
                {
                  name: 'auth',
                  value: 'system:role:edit',
                },
              ],
              on: {
                click: this.handleDetail.bind(this, row, 2),
              },
            }, '编辑角色'));
            actions.push(h('span', {
              domProps: {
                className: 'table-action table-action-delete',
              },
              directives: [
                {
                  name: 'auth',
                  value: 'system:role:delete',
                },
              ],
              on: {
                click: this.handleDelete.bind(this, row),
              },
            }, '删除'));
            return h('div', actions);
          },
        },
      ],
    };
  },
};
</script>
<style scoped lang='scss'>
/* @import url(); 引入css类 */

</style>
